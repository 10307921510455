/* Optional custom styles */
body {
  padding: 20px;
  margin: 0; /* Remove default margin */
}

.about-container {
  display: flex;
  /* max-width: 1000px; Adjust the maximum width of the container */
  max-width: 100vw;
  max-height: 100vh; /* Keeps vh in desired range */
  margin: 0 auto; /* Center the container horizontally */
}

.text-column {
  padding-top: 4%;            
}

.about-text {
  max-width: 100%;
}

.image-column {
    flex: 1; /* Take up remaining space */
    display: flex;
    padding-top: 4%;
}

.about-img {
  height: auto;
  display: block;
  max-width: 175% !important;
  object-fit: contain;
  margin-right: auto;
  margin-left: 0;
  margin-top: 0;
}

.rightfix {
  margin-left: 0;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}