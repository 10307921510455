/* Custom font */
@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
     url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
     url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
     url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
html, body {
  font-family: "proxima_nova_rgregular" !important;
}
.bold-font {
  font-weight: 1000 !important;
}
.thin-font {
  font-weight: 100 !important;
}

.header-bar {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  height: 20px;
  color: #333;
  position: fixed;
}

.header-cell {
  border: 1px solid white;
  background-color: #333;
  border-top: none;
  padding: 0; /* Remove cell padding */
}

.link {
  text-decoration: none;
  display: block;
  padding: 0 5px; /* Add consistent padding to all links */
}

/* Footer */
.footer-bar {
  z-index: 1;
  bottom: 0;
  left: 0;
  background-color: white; /* Change this to your desired background color */
  position: fixed;
  width: 100%;
}
.footer-cell {
  border:1px solid black;
}

/* Container of images */
#container {
  height: 100%; 
  width: 100%;
  display: flex;
  background-color: #FFFFFF;
}
/* Grid Styling */
.image-grid {
  max-width: 70%;
  max-height: 33vh;
}

.image-modal-hover {
  cursor: pointer;
}

.about-img {
  max-height: 50%;
}
.about-img-container {
  margin:50px ;
  float:right;
  top:-40px;
}

.about-link {
  text-decoration: none;
}

/* About page */
.about-container {
  position: relative;
  overflow-y: auto; 
}

/* Size of modal box */
@media screen {
  .modal-dialog {
    max-width: 70%;
    min-width: 70%;
  }

    .modal-content {
      max-height: 90%;
    }
  }
  .modal-body {
    background-color: #FFFFFF;
    margin-bottom: 0;
    height:100%;
    width: 100%;
    text-align: center;
    /* Adds scroll if text overflows */
    overflow: auto;
    margin-top: 3%; /* Adds padding on top of grid to separate from header */
    margin-left: auto;
    margin-right: auto;
  }

  .row-container {
    align-items: center;
    padding-top: 5%;
    padding-bottom: 2%;
  }

  /* Images and videos inside modal */
  .modal-image, .modal-video {
    object-fit: contain;
    max-width: 100%;
    max-height: 70vh; /* Limit height to 70% of viewport height */
    width: auto;
    height: auto;
    display: block;
    margin: 5% auto;
  }
  
  .modal-video {
    width: 100%; /* Videos typically need full width */
  }

  /* Text inside modal */
  .modal-body .project-header {
    max-width: 90%;
    margin-left: 25%;
    margin-right: 25%; 
    font-size: 16px;
    display: block;
    text-align: left;
  }

/* Mobile styling */
@media (max-width: 576px) {
  .modal-content {
    max-height: 80%;
  }

  .project-container {
    padding-top: 10%;
  }
  
  .row-container {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  /* Add this new rule */
  .modal-body .project-header {
    margin-left: 10%;
    margin-right: 10%;
    max-width: 100%;
  }

  /* Optional: Customize container padding if necessary */
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  /* Ensure images maintain aspect ratio on mobile */
  .modal-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 60vh; /* Slightly reduced height for mobile */
  }

  .image-grid, .ImageWithModal img {
    width: 100%;
    height: auto;
    object-fit: contain;
    aspect-ratio: auto; /* This ensures the original aspect ratio is maintained */
  }

  .about-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .col-12 {
    padding-top: 10%;
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}